import React, { useState, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useAuth } from "../context/UserState";
import { Spinner } from "react-bootstrap";


//Revert to this point
const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("This field is required!"),
  password: Yup.string().required("This field is required!"),
});

const Login: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  const { loginAction } = useAuth(); // Assume useAuth returns a context with setCurrentUser function

  const handleLogin = async (formValue: {
    email: string;
    password: string;
  }) => {
    const { email, password } = formValue;
    setMessage("");
    setLoading(true);

    try {
      await loginAction(email, password);
      setLoading(false);
    } catch (error: any) {
      let resMessage = "An unknown error occurred";
      if (error.response && error.response.status === 401) {
        resMessage =
          "The email or password you entered is incorrect. Please try again.";
      } else if (error instanceof Error) {
        resMessage = error.message;
      }
      setLoading(false);
      setMessage(resMessage);
    }
  };

  const initialValues = {
    email: "",
    password: "",
  };

  const renderErrorMessage = () => {
    if (!message) return null;
    return (
      <div className="mb-3">
        <div className="alert alert-danger" role="alert">
          {message}
        </div>
      </div>
    );
  };

  return (
    <div className="login-wrapper">
      <div className="login-branding-wrapper">
        <div className="branding-logo-container">
          <h1>Welcome to LionPro.ai</h1>
          <p>
            Welcome to our GenAl platform, where you can effortlessly integrate your proprietary documents into our Al
            repository, enriching it with expertise on your information. Simply ask our intelligent Al assistant to
            uncover the answers you seek.
          </p>
        </div>
      </div>

      <div className="login-form-wrapper">
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleLogin}>
          <Form className="form-container">
            <h3 id="sign-in-header">Sign in</h3>
            <div className="mb-3">
              <Field name="email" placeholder="Enter Email" type="text" className="form-control" />
              <ErrorMessage name="email" component="div" className="alert alert-danger" />
            </div>

            <div className="mb-4">
              <Field name="password" placeholder="Password" type="password" className="form-control" />
              <ErrorMessage name="password" component="div" className="alert alert-danger" />
            </div>

            <div className="mb-3 login-button-container">
              <button type="submit" className="login-button" disabled={loading}>
                Sign in
                {loading && <span className="spinner-border spinner-border-sm ms-1"></span>}
              </button>
            </div>
            {renderErrorMessage()}
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default Login;
