import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './App.css';
import EventBus from './common/EventBus';
import PrivateRoute from './components/PrivateRoute'; // Ensure this is updated for function components

import Login from './components/login.component';
import LoginSSO from './components/loginSSO.component';
import Admin from './components/admin/admin.component';
import Organization from './components/admin/organization.component';
import User from './components/admin/user.component';
import Settings from './components/settings/settings.component';
import Projects from './components/project/projects.component';
import Templates from './components/project/template/templates.component';
import EditTemplate from './components/project/template/editTemplates.component'
import CreateProjectFile from './components/project/createProjectFile/createProjectFile.component'
import TemplateQuestions from './components/project/createProjectFile/templateQuestions/templateQuestions.component'
import ChatWindow from './components/chat/ChatWindow';
import Footer from './components/footer.component';
import Header from './components/header.component';
import { useAuth } from './context/UserState'; // This should match the actual path to your context
import  Feedback  from './components/admin/feedback.component';
import Sidebar from './components/sidebar/sidebar.component';
import FileLibrary from './components/filelibrary/filelibrary.component';
import { usePolling } from './context/FileLibraryPollingProvider';

const App: React.FC = () => {
  const { currentUser, redirectURL, logOut } = useAuth();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    if (!currentUser) {
      setIsExpanded(false)
    }
  }, [currentUser])

  useEffect(() => {
    const handleLogout = () => {
      logOut();
    };

    EventBus.on('logout', handleLogout);

    return () => {
      EventBus.remove('logout', handleLogout);
    };
  }, [logOut]);

  const isLoginPage = location.pathname === '/login';
   
  return (
      <div id="app-container">
        {!isLoginPage && <Header />}
        {currentUser && <Sidebar isExpanded={isExpanded} setIsExpanded={setIsExpanded}/>}
        <div className={`appContent ${currentUser ? (isExpanded ? "expanded" : "") : "no-margin"}`}>
        <Routes>
          <Route path="/login" element={!currentUser ? <Login /> : <Navigate to={redirectURL|| "/chat"} />} /> 
          <Route path="/login/sso/:externalId" element={<LoginSSO />} /> 
          <Route path="/" element={<Navigate to={redirectURL|| "/chat"} replace />} />
          
          {/* Admin Routes */}
          <Route path="/admin" element={<PrivateRoute component={Admin} rolesPermitted={['admin']} />} />
          <Route path="/admin/organization" element={<PrivateRoute component={Organization} rolesPermitted={['admin']} />} />
          <Route path="/admin/user" element={<PrivateRoute component={User} rolesPermitted={['admin']} />} />
          <Route path="/admin/feedback" element={<PrivateRoute component={Feedback} rolesPermitted={['admin']} />} />

          {/* Org-Admin or Admin Routes */}
          <Route path="/library" element={<PrivateRoute component={FileLibrary} />} />

          {/* Project-Template or Admin Routes */}
          <Route path="/ai-documents" element={<PrivateRoute component={Projects} 
              rolesPermitted={['admin', 'project.all', 'project.project&template.all']} />} />
          <Route path="/templates" element={<PrivateRoute component={Templates} 
              rolesPermitted={['admin', 'project.project&template.all']} />} />
          <Route path="/template" element={<PrivateRoute component={EditTemplate} 
              rolesPermitted={['admin', 'project.project&template.all']} />} />
          <Route path="/generate" element={<PrivateRoute component={CreateProjectFile} 
              rolesPermitted={['admin', 'project.all', 'project.project&template.all']} />} />
          <Route path="/template-questions" element={<PrivateRoute component={TemplateQuestions} 
              rolesPermitted={['admin', 'project.all', 'project.project&template.all']} />} />
          
          {/* User Routes */}
          <Route path="/settings" element={<PrivateRoute component={Settings} />} />
          <Route path="/chat" element={<PrivateRoute component={ChatWindow} />} />

          {/* Catch-all route */}
          <Route path="*" element={currentUser ? <Navigate to="/chat" replace /> : <Navigate to="/login" replace />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
};

export default App;
