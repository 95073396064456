import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useRef } from "react"; 
import { useToast } from "../../../context/ToastProvider";
import '../projecttemplate.css'; 
import TemplateService from '../../../services/template.service'; 
import ApiTemplate from '../../../types/template.type'; 
import React from 'react';
import { Table, Accordion, Button, Alert, Modal, Spinner, Collapse } from "react-bootstrap";


const Templates: React.FC = () => { 
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [templates, setTemplates] = useState<ApiTemplate[]>([]); 
  const [templateFile, setTemplateFile] = useState<File>();
  const [templatesOptions, setTemplatesOptions] = useState<{ value: string; label: string }[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [templateName, setTemplateName] = useState<string>('');
  const [templateDescription, setTemplateDescription] = useState<string>('');
  const { addToast } = useToast();
  const [activeKey, setActiveKey] = useState<string>('');

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [templateToDelete, setTemplateToDelete] = useState<ApiTemplate | null>(null);
  const [openRow, setOpenRow] = useState(null);

  const officeExtensions = ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'];

  const toggleRow = (index: any) => {
    setOpenRow(openRow === index ? null : index); 
  };

  useEffect(() => { 
    fetchTemplates(); 
  }, []); 

  const fetchTemplates = async () => { 
    try { 
      const templates = await TemplateService.getTemplates(); 
      const templateDDList = templates.map((template: ApiTemplate) => ({ 
        value: template.id, 
        label: template.template_name, 
      })); 

      setTemplatesOptions(templateDDList); 
      setTemplates(templates); 
    } catch (error) { 
      console.log(error); 
    } 
  }; 

  const validateFields = () => { 
    const errors = [];

    if (templateName === '') {
        errors.push('Please enter a Template Name.');
    }
    if (typeof templateFile === 'undefined') {
        errors.push('Please choose template file to upload.');
    }

    if (errors.length > 0) {
        setErrorMessage(errors.join('\n'));
        return false;
    }

    setErrorMessage(null); 
    return true;
  }; 

  const isViewableExt = (template: ApiTemplate): boolean | null => {

    if (!template?.filename) {
      console.warn("Template has no filename or is undefined.");
      return false;
    }
  
    const templateExt = template.filename.split('.').pop()?.toLowerCase();
  
    if (!templateExt || templateExt === template.filename) {
      console.warn("No valid file extension found.");
      return false;
    }

    if (officeExtensions.includes(templateExt) || templateExt === "pdf" || templateExt === "csv" || templateExt === "txt") {
      return true;
    } else {
      return false;
    }
  };
  

  const handleViewClick = async (template: ApiTemplate) => {
    try {
      const url = await TemplateService.getTemplateDownloadURL(template.id);
      const encodedUrl = encodeURIComponent(url);
      const fileExtension = template.filename?.split('.').pop()?.toLowerCase() || '';

  
      if (officeExtensions.includes(fileExtension)) {        
        
        window.open(`https://view.officeapps.live.com/op/view.aspx?src=${encodedUrl}`, '_blank');
      
      } else if (fileExtension === 'pdf' || fileExtension === 'csv' || fileExtension === 'txt') {
            
        window.open(`https://docs.google.com/gview?embedded=true&url=${encodedUrl}`, '_blank');
      
      } 
    } catch (error) {
      console.log(error);
      addToast("error", "View Error", "An error occurred while attempting to open the document.");
    }
  };
  
  const handleDownloadClick = async (template: ApiTemplate) => {
    const template_id = template.id;
    const template_filename = template.filename
    try {
      const url = await TemplateService.getTemplateDownloadURL(template_id)
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", ""); 
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      addToast("success", "Template Downloaded", template_filename + " has been downloaded to your computer successfully.");
    } catch (error) {
      console.log(error);
      addToast("error", "Download Error", "An error occurred while attempting to download " + template_filename + ". Please try again.");
    }
  };

  const handleDeleteConfirm = async () => {
    if (templateToDelete) {
      try {
        setIsDeleting(true);
        await TemplateService.deleteTemplate(templateToDelete);
        setTemplates(prevTemplates => prevTemplates.filter(template => template.id !== templateToDelete.id));
        addToast("success", "Collection Deleted", `The project file "${templateToDelete.filename}" was deleted successfully.`);
      } catch (error) {
        console.log(error);
        addToast("error", "Delete Error", "An error occurred while deleting the collection. Please try again.");
      } finally {
        setShowDeleteModal(false);
        setIsDeleting(false);
      }
    }
  };

  const handleEditClick = (templateId: string) => {
    if (templateId) {
      navigate(`/template`, { state: { templateId } });
    }
  };

  const handleDeleteClick = (templateToDelete: ApiTemplate) => {
    setTemplateToDelete(templateToDelete);
    setShowDeleteModal(true);
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setTemplateToDelete(null);
  };


  const createNewTemplate = () => {
    navigate(`/template`);
  };

  const formatFileSize = (size: number) => {
    if (size >= 1048576) {
      return (size / 1048576).toFixed(2) + " MB";
    } else if (size >= 1024) {
      return (size / 1024).toFixed(2) + " KB";
    } else {
      return size + " bytes";
    }
  };

  return ( 
    <div className="projecttemplate-container"> 
       
        <div className="projecttemplate-content"> 
          <div className="projecttemplate-header">
            <h3>Document Generation Templates</h3>
          </div>  
          <div className="projecttemplate-body">
            <div className="d-flex justify-content-end mb-2">
              <Button variant="outline-primary" 
                  onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>{e.stopPropagation(); createNewTemplate();}} 
                  title="Create a new Template.">
                <i className="bi bi-file-earmark-plus fs-6 ms-0"></i> New Template
              </Button>
            </div>
              <Modal show={showDeleteModal} onHide={handleDeleteCancel}>
              <Modal.Header closeButton>
                <Modal.Title>Confirm Delete</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Please confirm you want to delete this template:<br></br>&nbsp;&nbsp;&nbsp;&nbsp; {templateToDelete?.template_name}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleDeleteCancel}>
                  Cancel
                </Button>
                <Button variant="danger" onClick={handleDeleteConfirm} disabled={isDeleting} style={{ width: "80px" }}>
                  {isDeleting ? <Spinner animation="border" size="sm" /> : "Confirm"}
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
          <Table hover>
            <thead>
              <tr>
                <th></th>
                <th>Name</th>
                <th>Created Date</th>
                <th className="text-end">Actions&nbsp;&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {templates.map((template, index) => (
                <React.Fragment key={template.id}>
                  <tr>
                  <td className="col-1">
                    <Button
                      variant="link"
                      onClick={() => toggleRow(index)} // Toggle the specific row based on the index
                      aria-controls={`collapse-row-${index}`} // Unique ID based on index
                      aria-expanded={openRow === index}
                    >
                      {openRow === index ? <i className="bi bi-dash-lg"></i> : <i className="bi bi-plus-lg"></i>}
                    </Button> 
                    </td>
                    <td className="col-6">
                      {template.template_name}
                    </td>
                    <td>
                      {template.uploaded_date
                        ? new Date(template.uploaded_date).toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                          })
                        : 'N/A'}
                    </td>
                    <td className="text-end col-2">
                      {isViewableExt(template) && (
                      <i className="bi bi-eye fs-6" title="View the Template." onClick={() => handleViewClick(template)}></i>
                      )}
                      <i className="bi bi-pencil fs-6" title="Edit the Template." onClick={() => handleEditClick(template?.id)}></i>
                      <i className="bi bi-trash fs-6" title="Delete the Template File." onClick={() => handleDeleteClick(template)}></i>
                      <i className="bi bi-download fs-6" title="Download the Template File." onClick={() => handleDownloadClick(template)}></i>
                    </td>
                  </tr>
                  <Collapse in={openRow === index}>
                    <tr id={`collapse-row-${index}`} className="bg-light">
                      <td></td>
                      <td colSpan={3}>
                        <div className="row">
                          <div className="col-md-2">
                            <i>File Name:</i>
                          </div>
                          <div className="col-md-9">
                            {template.filename}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-2">
                            <i>File Size:</i>
                          </div>
                          <div className="col-md-9">
                          {formatFileSize(template.file_size)}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-2">
                            <i>Description:</i>
                          </div>
                          <div className="col-md-9">
                            {template.template_description || 'No description available.'}
                          </div>
                        </div>                     
                      </td>
                    </tr>
                  </Collapse>
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        </div> 
      
    </div> 
  ); 
}; 
 
export default Templates;