import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { useAuth } from "../../../context/UserState";
import IUser from "../../../types/user.type";
import './createProjectFile.css'
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { useToast } from "../../../context/ToastProvider";

import CollectionService from '../../../services/collection.service';
import ProjectService from '../../../services/project.service';
import ProjectFileService from '../../../services/projectfile.service';
import ApiCollection from '../../../types/collection.type'; 
import ApiProject from '../../../types/project.type'; 
import ApiTemplateInstructionsObj from '../../../types/templateinstructionsobject.type'; 
import ApiSourceInfo from '../../../types/sourceinfo.type'; 
import TemplateService from '../../../services/template.service'; 
import ApiTemplate from '../../../types/template.type'; 
import { Button, Alert, Spinner } from "react-bootstrap";

const CreateProjectFile: React.FC = () => {
  const { currentUser } = useAuth() as { currentUser: IUser | null };
  const location = useLocation();
  const { project_id } = location.state || {}
  const [project, setProject] = useState<ApiProject>();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { addToast } = useToast();

  const [isLoading, setIsLoading] = useState(false);

  const [collections, setCollections] = useState<ApiCollection[]>([]); 
  const [templates, setTemplates] = useState<ApiTemplate[]>([]); 
  const [templateObj, setTemplateObj] = useState<ApiTemplate>(); 
  const [templateInstructionsObj, setTemplateInstructionsObj] = useState<ApiTemplateInstructionsObj>()
   
  const [selectedProject, setSelectedProject] = useState(''); 
  const [selectedTemplate, setSelectedTemplate] = useState<string>('');
  const [selectedCollection, setSelectedCollection] = useState<string>(''); 
  const [showCollection, setShowCollection] = useState<boolean>(false); 
  const [showProcessButton, setShowProcessButton] = useState<boolean>(false);
  const [requiresQuestions, setRequiresQuestions] = useState<boolean>(false); 
  const [sourceInfoObj, setSourceInfoObj] = useState<ApiSourceInfo[]>([]);
  
  const [templatesOptions, setTemplatesOptions] = useState<{ value: string; label: string }[]>([]);
  const [collectionsOptions, setCollectionsOptions] = useState<{ value: string; label: string }[]>([]); 
  

  useEffect(() => {
    if (project_id) {
      fetchProject();
      fetchTemplates();
      fetchCollections();
    }
  }, [currentUser, navigate])

  const fetchProject = async () => { 
    try { 
      const project = await ProjectService.getProject(project_id);
      setSelectedProject(project_id) 
      setProject(project)

      
    } catch (error) { 
      console.log(error); 
    } 
  };

  const fetchTemplates = async () => { 
    try { 
      const loadedTemplates = await TemplateService.getTemplates(); 
      
      const templateDDList = loadedTemplates.map((template: ApiTemplate) => ({ 
        value: template.id, 
        label: template.template_name, 
      })); 

      setTemplatesOptions(templateDDList); 
      setTemplates(loadedTemplates);
    } catch (error) { 
      console.log(error); 
    } 
  };

  const fetchCollections = async () => { 
    try { 
      const collections = await CollectionService.getCollections(); 
      const collectionDDList = collections.map((collection: ApiCollection) => ({ 
        value: collection.id, 
        label: collection.collection_name, 
      })); 

      setCollectionsOptions(collectionDDList); 
      setCollections(collections); 
    } catch (error) { 
      console.log(error); 
    } 
  };

  const handleTemplateChange = async (e: any) => {
    setShowProcessButton(false);
    if (typeof e === 'object' && e !== null && 'value' in e) {
      
      const template_id = e.value
      setSelectedTemplate(template_id);

      const templateObject = await TemplateService.getTemplate(template_id)
      setTemplateObj(templateObject)
      
      setIsLoading(true);
      setShowCollection(false);
      setSelectedCollection('');
      setSourceInfoObj([]);
      setTemplateInstructionsObj(undefined);
      setRequiresQuestions(true);

      const templateInstructionsObject = await TemplateService.getTemplateInstructions(template_id)
      
      if (templateInstructionsObject) {
        templateInstructionsObject.project_id = project_id;
        templateInstructionsObject.project_name = (project?.project_name || '')
        templateInstructionsObject.template_id = template_id;
        templateInstructionsObject.template_name = (templateObject?.template_name || '')
        setTemplateInstructionsObj(templateInstructionsObject)

        if (templateObject.file_type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
          setShowProcessButton(true);
        } else {
          setSourceInfoObj(templateInstructionsObject.instructions.map((inst: any) => ({
            name: inst.collection,
            description: inst.description,
            collection_id: ''
          })))

          setRequiresQuestions(true);
          
          setShowCollection(true);
          if (selectedCollection) {
              setShowProcessButton(true);
          } else {
            setShowProcessButton(false);
          }
          setRequiresQuestions(false);
        }
      }
      
      setIsLoading(false);
      
      

    } else {
      setSelectedTemplate('');
    }
  };

  useEffect(() => {
    if (collectionIsValid(sourceInfoObj)) {
      setShowProcessButton(true);
    } else {
      setShowProcessButton(false);
    }
  }, [sourceInfoObj, selectedCollection]);

  const collectionIsValid = (multiCollection: ApiSourceInfo[]) => {
    if (multiCollection.length > 0) {
      for (let conllectionConfig of multiCollection) {
          if (conllectionConfig.collection_id == '')
            return false;
      }
      return true;
    } else {
      return true;
    }
  }

  const handleMultiCollectionChange = (selectedOption: { value: string } | null, collectionName: string  ) => {
    setShowProcessButton(false);
    setSourceInfoObj(current =>
      current.map(config =>
        config.name === collectionName ? { ...config, collection_id: selectedOption?.value || '' } : config
      )
    );
  };

  const handleCancel = () => {
    navigate(`/ai-documents`);
  };

  const handleClearFields = () => {
    setSelectedProject('');
    setSelectedTemplate('');
    setSelectedCollection('');
  };

  const handleProcessTemplate = () => {  
    if (!requiresQuestions) {
      (async () => {
                    try {
                        await ProjectFileService.createProjectFile(selectedProject, selectedTemplate, sourceInfoObj);
                    } catch (error) {
                        console.error("Failed to initiate project file creation:", error);
                    }
                })();

      

      addToast("success", "AI Document Generating", "This may take a few minutes. Check back later for the AI document status to be marked active for downloading.");
      
      setTimeout(() => {
        handleClearFields();
        navigate(`/ai-documents`);
      }, 2000);
        
    } else {
      navigate(`/template-questions`, { state: { templateInstructionsObj } });
    }
  };

  return (
    <div className="createProjectFile-container">
      {currentUser && (
        <div className="createProjectFile-content">
          <div className="createProjectFile-header">
            <h3>Create AI-Generated Document</h3>
          </div>
          <div className="createProjectFile-body">
            {errorMessage && (
                  <Alert variant="danger">
                      {errorMessage.split('\n').map((msg, index) => (
                          <div key={index}>{msg}</div> // Display each error message in a separate line
                      ))}
                  </Alert>
                )} 
            <div className="mb-4"> 
              <label><i><strong>AI Document Group:</strong></i>&nbsp;&nbsp;&nbsp;{project?.project_name}</label>
            </div>
            <div className="mb-2"> 
              <label><i><strong>Document Generation Template:</strong></i></label> 
              <Select 
                value={templatesOptions.find((option) => option.value === selectedTemplate) || ''} 
                onChange={handleTemplateChange} 
                options={templatesOptions} 
                isClearable={true}
                menuPosition="fixed"
              /> 
            </div>
            {isLoading && (
              <div className="text-center">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            )}
            {showCollection && sourceInfoObj && sourceInfoObj.length > 0 && (
              sourceInfoObj.map((config, index) => (
                <div key={index} className="mb-2">
                  <label><i><strong>Collection:</strong></i> {config.name}</label>
                  <Select
                    value={collectionsOptions.find(option => option.value === config.collection_id) || ''}
                    onChange={(selectedOption) => {
                      // Check if selectedOption is an object and has the 'value' property
                      const isOptionObject = typeof selectedOption === 'object' && selectedOption !== null && 'value' in selectedOption;
                      const valueToPass = isOptionObject ? { value: selectedOption.value } : null;
                      handleMultiCollectionChange(valueToPass, config.name);
                    }}
                    options={collectionsOptions}
                    isClearable={true}
                    menuPosition="fixed"
                  />
                  <i><small className="text-muted">{config.description}</small></i>
                </div>
              ))
            )}
              <br /> 
              
                
                <div className="mb-2 d-flex justify-content-end"> 
                  <Button variant="outline-primary" className="d-flex align-items-center ps-0 fs-6" onClick={handleCancel}> 
                    <i className="bi bi-x-circle fs-5 me-1"></i> Cancel
                  </Button> &nbsp;&nbsp;
                  {showProcessButton && (
                    <Button variant="outline-primary" className="d-flex align-items-center ps-0 fs-6" onClick={handleProcessTemplate}> 
                      <i className="bi bi-play fs-5 me-1"></i> Generate AI Document 
                    </Button> 
                   )}
                </div>
              
           

          </div>
        </div>
      )}
    </div>
  );
};

export default CreateProjectFile;
