import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import IProject from "../../types/project.type";
import ProjectService from '../../services/project.service';

interface ProjectModalProps {
  projectToEdit: IProject | null;
  onSaveSuccess: (project: IProject) => void;
  onClose: () => void;
  onShow: boolean;
}

const ProjectModal: React.FC<ProjectModalProps> = ({ projectToEdit, onSaveSuccess, onClose, onShow }) => {
  const [projectName, setProjectName] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const projectNameRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (projectToEdit) {
      setProjectName(projectToEdit.project_name);
    } else {
      setProjectName(''); // Reset to blank when creating a new project
    }
  }, [projectToEdit]);

  useEffect(() => {
    if (onShow && projectNameRef.current) {
      setErrorMessage("")
      projectNameRef.current.focus();
    }
  }, [onShow]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        handleSave();
      }
    };

    if (formRef.current) {
      formRef.current.addEventListener('keypress', handleKeyPress);
    }

    return () => {
      if (formRef.current) {
        formRef.current.removeEventListener('keypress', handleKeyPress);
      }
    };
  }, [projectName]);

  const createProject = async (project: IProject) => {
    try {
      const response = await ProjectService.createProject(project);
      const newProject = { ...project, id: response.id };
      onSaveSuccess(newProject); // Pass the new project to onSaveSuccess
    } catch (error) {
      console.error('Error creating project:', error);
    } finally {
      onClose();
      setProjectName('')
    }
  };

  const updateProject = async (project: IProject) => {
    try {
      const updatedProject = await ProjectService.updateProject(project);
      onSaveSuccess(updatedProject); // Pass the updated project to onSaveSuccess
    } catch (error) {
      console.error('Error updating project:', error);
    } finally {
      onClose();
    }
  };

  const handleSave = async () => {
    if (projectName.length > 50) {
      setErrorMessage('Project name must be 50 characters or fewer.');
      return;
    }
    else if (projectName.length == 0) {
      setErrorMessage('Please enter a Project Name.');
      return;
    }

    setErrorMessage(null);

    if (projectToEdit) {
      const updatedProject: IProject = {
        ...projectToEdit,
        project_name: projectName,
      };
      await updateProject(updatedProject);
    } else {
      const newProject: IProject = {
        id: '',
        project_name: projectName,
      };
      await createProject(newProject);
    }
  };

  return (
    <>
      <Modal show={onShow} onHide={onClose} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => e.stopPropagation()}>
        <Modal.Header closeButton>
          <Modal.Title>{projectToEdit ? 'Edit Project' : 'Create AI Document Group'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
          <Form ref={formRef}>
            <Form.Group controlId="projectName">
              <Form.Label>Document Group Name</Form.Label>
              <Form.Control
                type="text"
                ref={projectNameRef}
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
              />
              <Form.Text className="text-muted">
                Maximum 50 characters.
              </Form.Text>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={(e) => {e.stopPropagation(); onClose();}}>
            Cancel
          </Button>
          <Button variant="primary" onClick={(e) => {e.stopPropagation(); handleSave();}}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProjectModal;
